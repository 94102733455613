const appConfig = {
  // apiPrefix: "https://staging.ucheed.dev/tlp",
  apiPrefix: "https://tlp.ucheed.dev/cms",
  // apiPrefix: "http://localhost/language-platform",
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "",
  enableMock: false,
};

export default appConfig;
