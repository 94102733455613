import ApiService from "../ApiService";

export async function getLanguages(options) {
  try {
    return ApiService.fetchData({
      url: "/wp-json/ucheed-json/v1/languages",
      method: "GET",
      params: options,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function addLanguage(data) {
  try {
    return ApiService.fetchData({
      url: "/wp-json/ucheed-json/v1/languages",
      method: "POST",
      data,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function deleteLanguage(id) {
  try {
    return ApiService.fetchData({
      url: `/wp-json/ucheed-json/v1/languages/${id}`,
      method: "DELETE",
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getLanguageById(id) {
  try {
    return ApiService.fetchData({
      url: `/wp-json/ucheed-json/v1/languages/${id}`,
      method: "GET",
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function updateLanguage(id, data) {
  try {
    return ApiService.fetchData({
      url: `/wp-json/ucheed-json/v1/languages/${id}`,
      method: "PUT",
      data,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}
