import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLanguages } from "services/data/LanguagesService";

export const initialState = {
  languages: [],
  loading: false,
  error: null,
};

export const fetchLanguages = createAsyncThunk(
  "data/language/fetchLanguages",
  async () => {
    const response = await getLanguages();
    return response.data;
  }
);

export const languageSlice = createSlice({
  name: "data/language",
  initialState,
  reducers: {
    addLanguage: (state, action) => {
      state.languages.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.languages = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.error = "error";
        state.loading = false;
      });
  },
});

export const { addLanguage } = languageSlice.actions;

export default languageSlice.reducer;
