import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import mockServer from "./mock";
import appConfig from "configs/app.config";
import "./locales";
import { QueryClient, QueryClientProvider } from "react-query";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const environment = process.env.NODE_ENV;

const queryClient = new QueryClient();

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== "production" && appConfig.enableMock) {
  mockServer({ environment });
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HistoryRouter history={history}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Theme>
                <Layout />
              </Theme>
            </LocalizationProvider>
          </HistoryRouter>
        </PersistGate>
      </Provider>
      <Toaster position="top-right" />
    </QueryClientProvider>
  );
}

export default App;
